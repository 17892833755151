import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tab from './Tab';

const Tabs = ({ children, switchTab, selected, scopeClass, titles }) => {
  const renderTitles = () => {
    const tabTitles = titles || children;

    return (
      <ul className="tabs__list">
        {tabTitles.map((tab, i) => {
          const tabData = tab.props || tab;

          return (
            <Tab
              key={i}
              name={tabData.name}
              text={tabData.label}
              currentTabId={selected}
              switchTab={switchTab}
              tabId={i}
              itemsCount={tabData.itemsCount}
            />
          );
        })}
      </ul>
    );
  };

  const selectedChild = React.Children.toArray(children)[selected];
  const renderContent = <div className="tabs__content">{selectedChild}</div>;

  return (
    <div className={classNames('tabs', `tabs--${scopeClass}`)}>
      {renderTitles()}
      {renderContent}
    </div>
  );
};

Tabs.propTypes = {
  selected: PropTypes.number,
  switchTab: PropTypes.func,
  children: PropTypes.node,
  scopeClass: PropTypes.string,
  titles: PropTypes.array
};

export default Tabs;
