import React from 'react';

const SearchToolsPlaceholder = () => {
  return (
    <div className="placeholder-st color-white">
      <div className="placeholder-st__top">
        <div className="placeholder-row placeholder-st__top-h1 animation" />
        <div className="placeholder-row placeholder-row--mt--10 placeholder-st__top-count animation" />
      </div>
      <div className="placeholder-st__bottom">
        <div className="placeholder-row placeholder-st__bottom-button animation" />
        <div className="placeholder-row placeholder-row--mt--10 placeholder-st__bottom-select animation" />
      </div>
    </div>
  );
};

export default SearchToolsPlaceholder;
