import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tab = ({ name, text, tabId, currentTabId, switchTab, itemsCount }) => {
  const tabClasses = classNames('tabs__list_i', `tabs__list_i--${name}`, {
    'tabs__list_i--selected': tabId === currentTabId
  });
  const handleClick = () => {
    switchTab(tabId);
  };

  return (
    <li
      className={tabClasses}
      id={`quick-links-tab-${name}`}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="tab"
    >
      {text}
      {itemsCount && <span>{`(${itemsCount})`}</span>}
    </li>
  );
};

Tab.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
  tabId: PropTypes.number,
  switchTab: PropTypes.func,
  currentTabId: PropTypes.number,
  itemsCount: PropTypes.number
};

export default Tab;
